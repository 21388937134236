@font-face {
    font-family: "Signika Bold";
    src: url('../fonts/signika-bold-webfont.eot')format('embedded-opentype'),
    url('../fonts/signika-bold-webfont.ttf') format('truetype'),
    url('../fonts/signika-bold-webfont.svg') format('svg'),
    url('../fonts/signika-bold-webfont.woff') format('woff');
}
@font-face {
    font-family: "Signika Light";
    src: url('../fonts/signika-light-webfont.eot')format('embedded-opentype'),
    url('../fonts/signika-light-webfont.ttf') format('truetype'),
    url('../fonts/signika-light-webfont.svg') format('svg'),
    url('../fonts/signika-light-webfont.woff') format('woff');
}
@font-face {
    font-family: "Signika Regular";
    src: url('../fonts/signika-regular-webfont.eot')format('embedded-opentype'),
    url('../fonts/signika-regular-webfont.ttf') format('truetype'),
    url('../fonts/signika-regular-webfont.svg') format('svg'),
    url('../fonts/signika-regular-webfont.woff') format('woff');
}
@font-face {
    font-family: "Signika Semibold";
    src: url('../fonts/signika-semibold-webfont.eot')format('embedded-opentype'),
    url('../fonts/signika-semibold-webfont.ttf') format('truetype'),
    url('../fonts/signika-semibold-webfont.svg') format('svg'),
    url('../fonts/signika-semibold-webfont.woff') format('woff');
}

body {
    background: #fafafa;
    font-family: "Signika Light", Helvetica, Arial, sans-serif;
    color: #333;
}

body .well {
    background-color: #fafafa;
}

body table {
    font-size: 16px;
}

body .table-striped > tbody > tr:nth-of-type(2n+1) {
    background-color: #f6f6f6;
}

body h2 {
    margin-bottom: 30px;
}

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

/* ==========================================================================
Development Ribbon
========================================================================== */
.ribbon {
    background-color: #a00;
    box-shadow: 0 0 10px #888;
    left: -3.5em;
    moz-box-shadow: 0 0 10px #888;
    moz-transform: rotate(-45deg);
    ms-transform: rotate(-45deg);
    o-transform: rotate(-45deg);
    overflow: hidden;
    position: absolute;
    top: 40px;
    transform: rotate(-45deg);
    webkit-box-shadow: 0 0 10px #888;
    webkit-transform: rotate(-45deg);
    white-space: nowrap;
    width: 15em;
    z-index: 9999;
    pointer-events: none;
}

.ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none;
}

/* ==========================================================================
Navbar
========================================================================== */
.navbar-advalorem.navbar-top .navbar-header {
    height: 105px;
    margin-left: 40px;
}

.navbar.navbar-advalorem a {
    color: #405ba6;
}

.navbar.navbar-advalorem .navbar-toggle {
    border-color: #ddd;
}

.navbar.navbar-advalorem .navbar-toggle .icon-bar {
    background-color: #888;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    color: #405ba6;
}

.navbar.navbar-advalorem .navbar-nav > li.active > a {
    background-color: transparent;
    text-decoration: underline;
}

.navbar.navbar-advalorem .navbar-nav > li.active > a:hover {
    background-color: #eee;
}

.navbar.navbar-advalorem .navbar-nav > li.dropdown.active > ul > li.active > a {
    background-color: transparent;
    text-decoration: underline;
}

.navbar.navbar-advalorem .navbar-nav > li.dropdown.active > ul > li.active > a:hover {
    background-color: #eee;
}

.navbar.navbar-advalorem .navbar-nav .dropdown-menu .active a {
    text-decoration: none;
}

.navbar-version {
    font-size: 10px;
    color: #ccc
}

.advalorem-banner img {
    width: 100%;
    height: auto;
}

.navbar-advalorem.navbar-bottom {
    margin-top: 20px;
}

.navbar-advalorem.navbar-bottom li > a{
    border-top: 6px solid transparent;
}

.navbar-advalorem.navbar-bottom li > a.link-home-bottom {
    border-top: 6px solid #3f5aa7;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand.logo {
    margin-top: 12px;
    height: 93px;
    border-bottom: 6px solid #3f5aa7;
}

.logo .logo-img {
    display: inline-block;
}

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
    margin: 50px auto 0 auto;
    width: 300px;
    font-size: 18px;
    font-weight: 200;
    line-height: 30px;
    background-color: #eee;
    border-radius: 6px;
    padding: 60px;
}

.hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px;
}

.footer h3 {
    font-family: "Signika Semibold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 21px;
    margin-bottom: 30px;
    color: #dd9933;
}

.footer .footer-tel {
    font-family: "Signika Bold", Helvetica, Arial, sans-serif;
    margin-top: 20px;
    font-size: 32px;
    margin-bottom: 20px;
    color: #405ba6;
}

/* ==========================================================================
Form styles
========================================================================== */
body .modal-title,
body h2,
.guarantee-modal-res .total-bonus {
    font-family: "Signika Bold", Helvetica, Arial, sans-serif;
    text-align: center;
    font-size: 32px;
    color: #405ba6;
}

form label,
.modal-body .field-readonly span {
    font-family: "Signika Semibold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 21px;
    color: #dd9933;
}
form .tooltip-label-input img {
    max-width: 22px;
    margin-bottom: 5px;
    margin-left: 10px;
}

form .forgotten-password-link {
    margin-top: 10px;
}

form .forgotten-password-link > a {
    font-family: "Signika Semibold", Helvetica, Arial, sans-serif;
    font-size: 16px;
    color: #7e7e7e;
    text-decoration: underline;
}

form .modal-footer {
    text-align: center;
}

.row.link-assurdem {
    margin-top: 20px;
}

.row.link-assurdem a {
    color: #405ba6;
    font-size: 24px;
}

.row.link-assurdem img {
    width: 20%;
}

input.form-login {
    border-color: #3f5aa7;
    border-radius: 0;
}

button.btn-primary,
a.btn-primary {
    border: 1px solid #405ba6;
    color: #405ba6;
    background-color:white;
    transition: all 0.15s linear;
}

button.btn-primary:hover,
a.btn-primary:hover {
    background-color:#405ba6;
    color: white;
    transition: all 0.15s linear;
}

/* ==========================================================================
Home Buttons
========================================================================== */
.row.home-buttons {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-top: 30px;
}

.row.home-buttons .home-button {
    flex: 0 1 auto;
    width: 175px;
    height: 175px;
    align-self: auto;
    border: 1px solid #d9d9d9;
    background-color: #f5f5f5;
}

.row.home-buttons .home-button > a {
    display: block;
    padding-top: 25px;
}

.row.home-buttons .home-button > a > img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 16px;
}

.row.home-buttons .home-button > a > span {
    display: block;
    text-align: center;
    color: black;
    font-size: 16px;
}

@media (max-width: 767px) {
    .row.home-buttons {
        justify-content: flex-start;
    }
}

/* ==========================================================================
Library
========================================================================== */

table .library-download-link,
table .library-download-link a {
    font-family: "Signika Semibold", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 21px;
    color: #dd9933;
}

table .library-download-link img {
    width: 10%;
    margin-right: 8px;
    margin-bottom: 3px;
}

/* ==========================================================================
Awesomefont icons
========================================================================== */
h3 > i {
    margin-right: 10px;
    color: #cccccc;
}

a > i {
    margin-left: 10px;
}

.background-icon {
    position: absolute;
    font-size: 300px;
    z-index: -1;
    opacity: 0.02;
    -webkit-transform: rotate( 25deg );
    -moz-transform: rotate( 25deg );
    transform: rotate( 25deg );
}

/* ==========================================================================
Result guarantee modal
========================================================================== */
.guarantee-modal-res .modal-header {
    background-color: #485aa4;
    color: white;
}

.guarantee-modal-res .modal-header button {
    color: white;
    font-size: 38px;
    opacity: 1;
    font-weight: inherit;
}

.guarantee-modal-res .field-readonly {
    text-align: center;
    font-size: 20px;
}

/* ==========================================================================
Messages d'alertes
========================================================================== */
body .alert-dismissible {
    font-size: 20px;
    background-color: transparent;
    border-color: transparent;
    text-align: center;
}

body .alert-danger.alert-dismissible {
    color: #be0b0e;
}


body .alert-success.alert-dismissible {
    color: #89b73d;
}

/* ==========================================================================
Generic styles
========================================================================== */
.error {
    color: white;
    background-color: red;
}

.pad {
    padding: 10px;
}

.break {
    white-space: normal;
    word-break:break-all;
}

.voffset  { margin-top: 2px; }
.voffset1 { margin-top: 5px; }
.voffset2 { margin-top: 10px; }
.voffset3 { margin-top: 15px; }
.voffset4 { margin-top: 30px; }
.voffset5 { margin-top: 40px; }
.voffset6 { margin-top: 60px; }
.voffset7 { margin-top: 80px; }
.voffset8 { margin-top: 100px; }
.voffset9 { margin-top: 150px; }

.readonly {
    background-color: #eee;
    opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer;
}

.hand {
    cursor: pointer;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
    top: inherit;
    display: block;
    font-size: 10px;
    max-width: 1024px;
}

#healthCheck .popover {
    margin-left: -50px;
}

.health-details {
    min-width: 400px;
}

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strengthBar {
    display:inline;
    list-style:none;
    margin:0;
    margin-left:15px;
    padding:0;
    vertical-align:2px;
}

.point:last {
    margin:0 !important;
}
.point {
    background:#DDD;
    border-radius:2px;
    display:inline-block;
    height:5px;
    margin-right:1px;
    width:20px;
}

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert{
    text-overflow: ellipsis;
}
.alert pre{
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
}

.alert .popover pre {
    font-size: 10px;
}

.alerts .toast {
    position: fixed;
    width: 100%;
}

.alerts .toast.left {
    left: 5px;
}

.alerts .toast.right {
    right: 5px;
}

.alerts .toast.top {
    top: 55px;
}

.alerts .toast.bottom {
    bottom: 55px;
}

@media screen and (min-width: 480px) {
    .alerts .toast {
        width: 50%;
    }
}

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left { padding-left: 0 !important; }
.no-padding-right { padding-right: 0 !important; }
.no-padding-top { padding-top: 0 !important; }
.no-padding-bottom { padding-bottom: 0 !important; }
.no-padding { padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min { width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
 http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row;
   flex-direction: row;
   -webkit-justify-content: flex-end;
   justify-content: flex-end;
}

.jh-table > tbody > tr > td {
    /* Align text in td vertically (top aligned by Bootstrap) */
    vertical-align: middle;
}

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
    /* less visible sorting icons */
    opacity: 0.5;
}

.jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > dd {
    margin-bottom: 15px;
}

@media screen and (min-width: 768px) {
    .row.jh-entity-details > dt {
        margin-bottom: 15px;
    }

    .row.jh-entity-details > dd {
        border-bottom: 1px solid #eee;
        padding-left: 180px;
        margin-left: 0;
    }
}

@media screen and (min-width: 1200px) {
    /*/!* Ad Valorem Logo *!/*/
    .navbar-advalorem.navbar-top .navbar-header {
        margin-left: 40px;
    }

    .navbar-brand.logo {
        margin-left: auto !important;
        margin-right: auto;
    }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
    cursor: pointer;
}

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup  > li > div.uib-datepicker > table .btn-default {
    border: 0;
}

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup  > li > div.uib-datepicker > table:focus {
    outline: none;
}



/* jhipster-needle-css-add-main JHipster will add new css style */
